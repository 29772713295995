<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Input Data Payment'" />

    <div>
      <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
        @beforedelete="deleteFile($event)"
        ref="excelFile"
      ></VueFileAgent>
      <button-form
        :submit="submitExcelPayment"
        :buttonText="'Upload'"
        :loading="loading"
        class="pt-8"
      />

      <v-divider class="my-6" />

      <h4 class="pb-4">Form Input</h4>
      <form-payment :formPayment="formNewPayment" />
      <button-form :submit="submitPayment" :loading="loading" class="pt-8" />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperTransaction from "../../components/Stepper/StepperTransaction.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormTransaction from "../../components/Form/FormTransaction.vue";
import FormPayment from "../../components/Form/FormPayment.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperTransaction,
    ButtonForm,
    FormTransaction,
    FormPayment,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewPayment: {
        penyelenggara: "",
        idPinjaman: "",
        borrower: null,
        lender: null,
        idTransaksi: "",
        idPembayaran: "",
        tanggalJatuhTempoSelanjutnya: "",
        tanggalJatuhTempo: "",
        tanggalPenyaluranDana: "",
        tanggalPembayaranBorrower: "",
        tanggalPembayaranPenyelenggara: "",
        tanggalPelunasanBorrower: "",
        tanggalPelunasanPenyelenggara: "",
        report: null,
        sisaPinjamanBerjalan: "",
        statusPinjaman: null,
        denda: "",
        nilaiPembayaran: "",
      },
    };
  },

  mounted() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
  },

  updated() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-payment");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    deleteFile(fileRecord) {
      this.$refs.excelFile.deleteFileRecord(fileRecord);
      this.uploadedFile = "";
    },
    async submitExcelPayment() {
      if (this.uploadedFile) {
        this.loading = true;
        let payload = new FormData();
        payload.append("table", "pembayaran");
        payload.append("json", "bulk");
        payload.append("file", this.uploadedFile.file);
        await store.dispatch("office/addExcel", payload).then((r) => {
          if (r) {
            console.log(r, "sukses");
            this.showDialog = !this.showDialog;
            this.loading = false;
          } else {
            this.showErrDialog = true;
            this.loading = false;
          }
        });
      } else {
        this.showErrDialog = true;
      }
    },
    async submitPayment() {
      this.uploadedFile = "";
      this.loading = false;
      try {
        console.log(this.formNewPayment);
        await store
          .dispatch("office/addNewPayment", this.formNewPayment)
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
